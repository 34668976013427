import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Grid, Typography} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {AppUrls} from "../components/common/AppUrls";
import {SessionStatus} from "../components/provider/SessionStatus";
import {ServerApi} from "../components/provider/ServerApi";

const TopPage = () => {
	const driver = SessionStatus.getDriver();
	const vehicle = SessionStatus.getVehicle();
	const organization = SessionStatus.getUserOrganization();
	const navigate = useNavigate();
	const isTuring = organization === "turing";

	const [disableStartButton, setDisableStartButton] = useState<boolean>(false)

	const handleStartDrive = () => {
		setDisableStartButton(true);
		ServerApi.get("/api/data/route/not_finished", {
			vehicle: vehicle.id,
		}).then((data) => {
			if (data.found) {
				navigate(AppUrls.drive_report_show.path(data.vehicle_id, data.route_number));
			} else {
				setDisableStartButton(false);
				window.confirm("未走行ルートはもうありません");
			}
		}).catch(() => {
			setDisableStartButton(false);
			console.log("Failed");
		})
	}

	useEffect(() => {
		if (!SessionStatus.isLoggedIn()) {
			navigate("/login");
		}
	})

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			<Grid item xs={12} sx={{marginX: 1}}>
				<Card sx={{margin: "auto", mt: 1}}>
					<CardContent>
						<Typography variant="subtitle1" color="text.secondary">
							ドライバー名
						</Typography>
						<Typography variant="caption" component="div" sx={{color: "#666", mb: -0.5}}>
							{driver.id}
						</Typography>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography variant="h5" component="div" sx={driver.selected ? {} : {color: "#a00"}}>
								{driver.name ?? "未選択"}
							</Typography>
							<Button variant="contained" color="primary" component={Link}
									to={AppUrls.driver_select + "?page=1"}>
								選択
							</Button>
						</Box>
					</CardContent>
					<CardContent>
						<Typography variant="subtitle1" color="text.secondary">
							車両番号
						</Typography>
						<Typography variant="caption" component="div" sx={{color: "#666", mb: -0.5}}>
							{vehicle.id}
						</Typography>
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<Typography variant="h5" component="div" sx={vehicle.selected ? {} : {color: "#a00"}}>
								{vehicle.name ?? "未選択"}
							</Typography>
							<Button variant="contained" color="primary" component={Link}
									to={AppUrls.vehicle_select + "?page=1"}>
								選択
							</Button>
						</Box>
					</CardContent>
				</Card>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sx={{m: 5, mb: 1}}>
					<Button
						variant="contained"
						fullWidth
						style={{fontSize: "2.0rem", textAlign: 'center', height: "100px"}}
						onClick={handleStartDrive}
						disabled={!driver.selected || !vehicle.selected || disableStartButton}
					>
						ルート走行
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} sx={{m: 5, mb: 1}}>
					<Button
						variant="contained"
						fullWidth
						component={Link}
						style={{fontSize: "1.2rem", textAlign: 'center', height: "70px"}}
						to={AppUrls.drive_calibration_log}
						disabled={!driver.selected || !vehicle.selected || disableStartButton}
					>
						キャリブレーション走行
					</Button>
				</Grid>
			</Grid>
			{
				isTuring && (
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{m: 5, mb: 2}}>
							<Button
								variant="contained"
								fullWidth
								component={Link}
								style={{fontSize: "1.2rem", textAlign: 'center', height: "70px"}}
								to={AppUrls.drive_free_drive}
								disabled={!driver.selected || !vehicle.selected || disableStartButton}
							>
								ルートなし走行
							</Button>
						</Grid>
					</Grid>
				)
			}
			{
				isTuring && (
					<Grid container spacing={2} sx={{m: 1}}>
						<Grid item xs={4}>
							<Button
								variant="contained"
								fullWidth
								style={{fontSize: "2.0rem", textAlign: 'center', height: "100px"}}
								disabled={true}
							>
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="contained"
								fullWidth
								style={{fontSize: "2.0rem", textAlign: 'center', height: "100px"}}
								disabled={true}
							>
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant="contained"
								fullWidth
								component={Link}
								style={{fontSize: "1.4rem", textAlign: 'center', height: "100px"}}
								to={AppUrls.settings}
								disabled={disableStartButton}
							>
								その他
							</Button>
						</Grid>
					</Grid>
				)
			}
		</Grid>
	);
};

export default TopPage;
