import {ServerApi} from "./ServerApi";
import {CreateParams, DataProvider, GetOneParams, HttpError} from "ra-core";
import {AxiosError} from "axios";

const SessionError = () => {
	return new HttpError("Session error", 401, "Authentication required.");
}

export const customDataProvider = {
	getList: async (resource: any, params: any) => {
		const {page, perPage} = params.pagination;
		const {field, order} = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			page: page - 1,
			page_size: perPage,
			filter: JSON.stringify(params.filter),
		};
		const url = `/api/data/${resource}/list`;

		return ServerApi.get(url, query).then((data) => {
			return {
				data: data.data,
				total: data.total,
			};
		}).catch((e: AxiosError) => {
			if (e.status === 401) {
				throw SessionError();
			}
			throw new Error('Fetch failed');
		});
	},

	getOne: async (resource: string, params: GetOneParams) => {
		const query = (typeof params.id === "string") ? {id: params.id} : params.id;
		const url = `/api/data/${resource}/get`;

		return ServerApi.get(url, query).then((data) => {
			return {
				data: data.data,
			};
		}).catch((e: AxiosError) => {
			if (e.status === 401) {
				throw SessionError();
			}
			throw new Error('Fetch failed');
		});
	},

	getMany: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),

	getManyReference: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),

	update: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),

	updateMany: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),

	create: async (resource: any, params: CreateParams) => {
		const url = `/api/data/${resource}/create`;
		return ServerApi.post(url, params.data).then((data) => {
			return {
				data: data.data,
			};
		}).catch((e: AxiosError) => {
			if (e.status === 401) {
				throw SessionError();
			}
			throw new Error('Create failed');
		});
	},

	delete: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),

	deleteMany: async (resource: any, params: any) => Promise.resolve({data: [], total: 0}),
} as DataProvider;

export default customDataProvider;
