export const PrettyDatetime = {
	diff_to_pretty: (seconds: number): string => {
		if (seconds < 3600) {
			const min = Math.round(seconds / 60);
			return `${min}分前`
		} else if (seconds < 86400) {
			const hour = Math.round(seconds / 3600);
			return `${hour}時間前`
		} else {
			const day = Math.round(seconds / 86400);
			return `${day}日前`
		}
	},

	to_japanese_datetime: (timestamp: number): string => {
		const datetime = new Date(timestamp);
		return [
			datetime.getFullYear(),
			"年",
			datetime.getMonth() + 1,
			"月",
			datetime.getDate(),
			"日 ",
			datetime.getHours(),
			":",
			datetime.getMinutes() < 10 ? "0" : "",
			datetime.getMinutes(),
			":",
			datetime.getSeconds() < 10 ? "0" : "",
			datetime.getSeconds()
		].join("");
	},

	to_pretty_datetime: (timestamp: number, now: number): string => {
		return PrettyDatetime.to_japanese_datetime(timestamp) +
			" (" + PrettyDatetime.diff_to_pretty(Math.round((now - timestamp) / 1000)) + ")";
	}
}
