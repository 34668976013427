import {CreateResult, RecordContextProvider, SimpleForm, TextInput, useDataProvider} from 'react-admin';
import {Box, Button, Card, Grid, SxProps, Typography} from "@mui/material";
import React, {SyntheticEvent, useState} from "react";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {SessionStatus} from "../../../components/provider/SessionStatus";
import {OperationHistory} from "../../../components/common/OperationHistory";
import {useFormContext} from "react-hook-form"
import {PrettyDatetime} from "../../../components/common/PrettyDatetime";
import {Link} from "react-router-dom";

const sxButton: SxProps = {
	minWidth: 90,
	height: 90,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}

const sxCaption1: SxProps = {
	fontSize: "1.0em",
	height: "30px",
}

type OperationStatusStarted = {
	is_started: true,
	started_at: number,
	location: string | null,
};

type OperationStatusNotStarted = {
	is_started: false,
	started_at: null,
	location: null,
}

type OperationStatus = OperationStatusStarted | OperationStatusNotStarted;

/**
 * 最後の操作状態
 */
const OperationStatusStore = {
	load: (): OperationStatus => {
		const status_json = localStorage.getItem("free-drive-status");
		if (status_json === null) {
			return {
				is_started: false,
				started_at: null,
				location: null,
			};
		}

		const status = JSON.parse(status_json) as OperationStatus;
		const now = Date.now();

		if (status.is_started && status.started_at + 86400 < now) {
			return {
				is_started: false,
				started_at: null,
				location: null,
			};
		}

		return status;
	},

	save: (status: OperationStatus) => {
		localStorage.setItem("free-drive-status", JSON.stringify(status));
	},

	clear: () => {
		localStorage.removeItem("free-drive-status");
	}
}

const CardCell = (props: { caption: string, content: string }) => {
	return (
		<Box sx={{padding: 1.5, border: "1px solid #ccc", borderRadius: 2}}>
			<Typography variant="caption" gutterBottom color="text.secondary">
				{props.caption}
			</Typography>
			<Typography variant="body1" sx={{fontSize: "1.4rem"}}>
				{props.content}
			</Typography>
		</Box>
	);
}

const FormButton = (props: { disabled: boolean, onClick: (form: formData) => void, children: any }) => {
	const ctx = useFormContext();

	const handleClick = () => {
		const form = ctx.getValues() as formData;
		props.onClick(form);
	}

	return (
		<Button variant="contained" sx={sxButton} onClick={handleClick} disabled={props.disabled}>
			{props.children}
		</Button>
	);
}

const CopyButton = (props: {
	value: string | null,
	copy_to: string,
	run_after: ((value: string) => void) | undefined,
	children: any
}) => {
	const ctx = useFormContext();

	const handleClick = () => {
		ctx.setValue(props.copy_to, props.value || "");
		if (props.run_after) {
			props.run_after(props.value || "");
		}
	}

	return (
		<Button
			variant="outlined"
			sx={{fontSize: "0.8em", padding: 0.5, ml: 2}}
			onClick={handleClick}>
			{props.children}
		</Button>
	);
}

interface formData {
	location: string | undefined,
}

export const DriveFreeDrivePage = () => {
	const dataProvider = useDataProvider();
	const now = Date.now();
	const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);
	const [isLocationFilled, setIsLocationFilled] = useState<boolean>(false);

	// セッション確認
	const current_driver = SessionStatus.getDriver();
	const current_vehicle = SessionStatus.getVehicle();
	const isSelected = current_driver.selected && current_vehicle.selected;

	// 最終操作状態取得
	const lastStatus = OperationStatusStore.load();
	const [statusIsStarted, setStatusIsStarted] = useState<boolean>(lastStatus.is_started);
	const [statusLocation, setStatusLocation] = useState<string>(lastStatus.location || "");
	const [statusStartedAt, setStatusStartedAt] = useState<number | null>(lastStatus.started_at);

	// イベントハンドラ: 走行エリア変更
	const handleChangeLocation = (event: SyntheticEvent) => {
		const target = event.target as HTMLInputElement;
		setIsLocationFilled(target.value !== "");
	}

	const updateFormStatus = (value: string) => {
		setIsLocationFilled(value !== "");
	}

	// クリックイベント: 走行開始
	const handleReportStarted = (form: formData) => {
		setIsButtonsDisabled(true);
		dataProvider.create("free_drive", {
			data: {
				vehicle: current_vehicle.id,
				driver: current_driver.id,
				is_start: true,
				location: form.location,
			}
		}).then((response: CreateResult) => {
			const res = response.data;
			OperationHistory.appendRow({
				type: "free_drive",
				id: res.id,
				timestamp: res.data.timestamp,
				is_start: res.data.is_start,
				vehicle_id: res.data.vehicle_id,
				vehicle_name: res.data.vehicle_name,
				driver_id: res.data.driver_id,
				driver_name: res.data.driver_name,
				location: res.data.location,
			});

			OperationStatusStore.save({
				is_started: true,
				started_at: Date.parse(res.data.timestamp),
				location: res.data.location,
			})
			setStatusIsStarted(true);
			setStatusLocation(res.data.location);
			setStatusStartedAt(Date.parse(res.data.timestamp));
			setIsButtonsDisabled(false);
		}).catch(() => {
			console.log("Failed.");
			setIsButtonsDisabled(false);
		});
	}

	// クリックイベント: 走行終了
	const handleReportFinished = (form: formData) => {
		setIsButtonsDisabled(true);
		dataProvider.create("free_drive", {
			data: {
				vehicle: current_vehicle.id,
				driver: current_driver.id,
				is_start: false,
				location: form.location,
			}
		}).then((response: CreateResult) => {
			const res = response.data;
			OperationHistory.appendRow({
				type: "free_drive",
				id: res.id,
				timestamp: res.data.timestamp,
				is_start: res.data.is_start,
				vehicle_id: res.data.vehicle_id,
				vehicle_name: res.data.vehicle_name,
				driver_id: res.data.driver_id,
				driver_name: res.data.driver_name,
				location: res.data.location,
			});
			OperationStatusStore.clear();
			setIsButtonsDisabled(false);
			setStatusIsStarted(false);
			setStatusStartedAt(null);
			setStatusLocation("");
		}).catch(() => {
			console.log("Failed.");
			setIsButtonsDisabled(false);
		});
	}

	return (
		<RecordContextProvider>
			<Typography sx={{
				textAlign: "center",
				mt: 2,
				mb: 1,
				fontSize: "1.6rem",
			}}>
				ルートなし走行報告
			</Typography>
			<Box sx={{padding: 2}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CardCell caption="ドライバー" content={current_driver?.display_name || "-"}/>
					</Grid>

					<Grid item xs={4}>
						<CardCell caption="車両番号" content={current_vehicle?.id || "-"}/>
					</Grid>
					<Grid item xs={8}>
						<CardCell caption="車両名" content={current_vehicle?.name || "-"}/>
					</Grid>
				</Grid>
			</Box>
			<SimpleForm id="post_create_form" toolbar={false} sx={{margin: 2}}>
				<Grid container spacing={2}>
					<TextInput source="location" name="location" label="走行エリア" onChange={handleChangeLocation}/>
				</Grid>
				<Grid container spacing={2}>
					<Card variant="outlined" sx={{width: "100%", padding: 1}}>
						<Grid container>
							<Grid item xs={4}>
								<Typography variant="caption" color="text.secondary">
									走行状態
								</Typography>
								<Typography variant="h6" sx={{color: statusIsStarted ? "#46f" : "#f64"}}>
									{statusIsStarted ? "走行中" : "未走行"}
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="caption" color="text.secondary">
									走行エリア
								</Typography>
								<Typography variant="body1">
									{statusLocation}
									{
										//statusIsStarted && <CopyButton value={statusLocation} copy_to="location"
										//							   run_after={updateFormStatus}>コピー</CopyButton>
										null
									}
								</Typography>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="caption" color="text.secondary">
									走行開始時刻
								</Typography>
								<Typography variant="body1" sx={{mt: 0.5}}>
									{statusStartedAt ? PrettyDatetime.to_pretty_datetime(statusStartedAt, now) : "-"}
								</Typography>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid container spacing={2} sx={{mt: 3}}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						sx={{width: '80%', margin: '0 auto', padding: 1}}
					>
						<Box display="flex" flexDirection="column" alignItems="center">
							<FormButton onClick={handleReportStarted}
										disabled={isButtonsDisabled || !isSelected}>
								<PlayCircleIcon fontSize="large"/>
							</FormButton>
							<Typography variant="body2" mt={1} sx={sxCaption1}>
								開始報告
							</Typography>
						</Box>

						<Box display="flex" flexDirection="column" alignItems="center">
							<FormButton onClick={handleReportFinished}
										disabled={isButtonsDisabled || !isSelected}>
								<StopCircleIcon fontSize="large"/>
							</FormButton>
							<Typography variant="body2" mt={1} sx={sxCaption1}>
								終了報告
							</Typography>
						</Box>
					</Box>
				</Grid>
			</SimpleForm>
			<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mt: 0, mb: 8}}>
				<Grid item xs={6}>
					<Button
						variant="contained"
						fullWidth
						component={Link}
						to="/"
						style={{fontSize: '1.0rem'}}
						disabled={isButtonsDisabled}
					>
						戻る
					</Button>
				</Grid>
			</Grid>
		</RecordContextProvider>
	);
}
