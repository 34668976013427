import {Menu} from 'react-admin';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeIcon from '@mui/icons-material/Home';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import {AppUrls} from "../common/AppUrls";
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import HistoryIcon from '@mui/icons-material/History';

export const CustomMenu = () => (
	<Menu>
		<Menu.DashboardItem to="/" primaryText="ホーム" leftIcon={<HomeIcon/>}/>
		<Menu.Item to={AppUrls.vehicle_select+"?page=1"} primaryText="車両選択" leftIcon={<DriveEtaIcon/>}/>
		<Menu.Item to={AppUrls.driver_select+"?page=1"} primaryText="ドライバー選択" leftIcon={<AirlineSeatReclineExtraIcon/>}/>
		<Menu.Item to={AppUrls.drive_route_list} primaryText="ルート一覧" leftIcon={<FactCheckIcon/>}/>
		<Menu.Item to={AppUrls.drive_report_history} primaryText="報告送信履歴" leftIcon={<HistoryIcon/>}/>
	</Menu>
);
