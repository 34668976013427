import {Datagrid, List, TextField} from 'react-admin';
import {useNavigate, useParams} from 'react-router-dom';
import {AppUrls} from "../../../components/common/AppUrls";
import {SessionStatus} from "../../../components/provider/SessionStatus";

const dataRowSx = (record: any, index: any) => ({
	height: '60px',
});

export const DriverList = () => {
	const navigate = useNavigate();

	const onRowClicked = (id: any, resource: any, row: any): any => {
		SessionStatus.setDriver(row.id,row.name);
		navigate(AppUrls.home);
	};

	return (
		<List exporter={false} title="ドライバー選択" perPage={100}>
			<Datagrid bulkActionButtons={false} rowSx={dataRowSx} rowClick={onRowClicked}>
				<TextField source="id" sortable={false} label="ID"/>
				<TextField source="name" sortable={false} label="氏名"/>
			</Datagrid>
		</List>
	);
}
