import React, {useState} from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppUrls} from "../../../components/common/AppUrls";
import {ServerApi} from "../../../components/provider/ServerApi";
import {SessionStatus} from "../../../components/provider/SessionStatus";

const DriveFinished = () => {
	const {vehicle, route} = useParams();
	const navigate = useNavigate();
	const [disableButtons, setDisableButtons] = useState<boolean>(false)

	const handleNextRoute = () => {
		setDisableButtons(true);
		ServerApi.get("/api/data/route/not_finished", {
			vehicle: vehicle ?? "",
		}).then((data) => {
			if (data.found) {
				navigate(AppUrls.drive_report_show.path(data.vehicle_id, data.route_number));
			} else {
				setDisableButtons(false);
				window.confirm("未走行ルートはもうありません");
			}
		}).catch(() => {
			setDisableButtons(false);
			console.log("Failed");
		})
	}

	const handleFinishWork = () => {
		setDisableButtons(true);
		if (window.confirm("ドライバー選択をリセットしてホームへ戻ります。よろしいですか？")) {
			SessionStatus.resetDriver();
			navigate(AppUrls.home);
		} else {
			setDisableButtons(false);
		}
	}


	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			sx={{width: '100%', margin: '0 auto', padding: 1}}
		>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Typography
					variant="h4"
					align="center"
					color="primary"
					sx={{
						marginTop: "3rem",
						marginBottom: "3rem",
					}}
				>
					走行を終了しました
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: 5,
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								onClick={handleNextRoute}
								sx={{height: "100px"}}
								style={{fontSize: '1.9rem'}}
								disabled={disableButtons}
							>
								次のルートへ
							</Button>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								component={Link}
								to={AppUrls.drive_report_error.path(vehicle, route)+"?return=finished"}
								sx={{height: "100px"}}
								style={{fontSize: '1.9rem'}}
								disabled={disableButtons}
							>
								エラー報告
							</Button>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								onClick={handleFinishWork}
								sx={{height: "100px"}}
								style={{fontSize: '1.9rem'}}
								disabled={disableButtons}
							>
								業務終了
							</Button>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								component={Link}
								to={AppUrls.drive_report_show.path(vehicle, route)}
								style={{fontSize: '1.9rem'}}
								disabled={disableButtons}
							>
								戻る
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Box>
	);
};

export default DriveFinished;
