export const SessionStatus = {
	isLoggedIn: () => {
		return (localStorage.getItem('user_id') ?? null) !== null;
	},

	start: (user_id: string, user_name: string, organization: string) => {
		SessionStatus.clear()
		localStorage.setItem('user_id', user_id);
		localStorage.setItem('user_name', user_name);
		localStorage.setItem('organization', organization);
	},

	resume: (user_id: string, user_name: string, organization: string) => {
		localStorage.setItem('user_id', user_id);
		localStorage.setItem('user_name', user_name);
		localStorage.setItem('organization', organization);
	},

	clear: () => {
		localStorage.removeItem('user_id');
		localStorage.removeItem('user_name');
		localStorage.removeItem('organization');
		localStorage.removeItem('driver_id');
		localStorage.removeItem('driver_name');
		localStorage.removeItem('vehicle_id');
		localStorage.removeItem('vehicle_name');
	},

	getUserOrganization: () => {
		return localStorage.getItem('organization');
	},

	setDriver: (driver_id: string, driver_name: string) => {
		localStorage.setItem('driver_id', driver_id);
		localStorage.setItem('driver_name', driver_name);
	},

	resetDriver: () => {
		localStorage.removeItem('driver_id');
		localStorage.removeItem('driver_name');
	},

	getDriver: () => {
		const driver_id = localStorage.getItem('driver_id') ?? null;
		const driver_name = localStorage.getItem('driver_name') ?? null;

		return {
			display_name: driver_id === null ? "未選択" : `${driver_name} (${driver_id})`,
			selected: driver_id !== null,
			id: driver_id,
			name: driver_name,
		};
	},

	setVehicle: (vehicle_id: string, vehicle_name: string) => {
		localStorage.setItem('vehicle_id', vehicle_id);
		localStorage.setItem('vehicle_name', vehicle_name);
	},

	resetVehicle: () => {
		localStorage.removeItem('vehicle_id');
		localStorage.removeItem('vehicle_name');
	},

	getVehicle: () => {
		const vehicle_id = localStorage.getItem('vehicle_id') ?? null;
		const vehicle_name = localStorage.getItem('vehicle_name') ?? null;
		return {
			display_name: vehicle_id === null ? "未選択" : `${vehicle_name} (${vehicle_id})`,
			selected: vehicle_id !== null,
			id: vehicle_id,
			name: vehicle_name,
		}
	},
}
