import {Datagrid, Empty, FunctionField, List, TextField} from 'react-admin';
import {useNavigate} from 'react-router-dom';
import {AppUrls} from "../../../components/common/AppUrls";
import {SessionStatus} from "../../../components/provider/SessionStatus";

const dataRowSx = (record: any, index: any) => ({
	height: '60px',
});

export const RouteList = () => {
	const navigate = useNavigate();
	const vehicle = SessionStatus.getVehicle();

	const onRowClicked = (id: any, resource: any, row: any): any => {
		navigate(AppUrls.drive_report_show.path(row.vehicle_id, row.route_number));
	};

	const renderStatus = (record: any) => {
		return record.status ? (<span>走行済</span>) : (<span style={{color: "red"}}>未</span>);
	}

	if (!vehicle.selected) {
		return <Empty/>
	}

	return (
		<List exporter={false} title="ルート選択" filter={{vehicle_id: vehicle.id}} perPage={100}>
			<Datagrid bulkActionButtons={false} rowSx={dataRowSx} rowClick={onRowClicked}>
				<TextField source="vehicle_id" sortable={false} label="車両ID"/>
				<TextField source="route_number" sortable={false} label="番号"/>
				<FunctionField sortable={false} label="状態" render={renderStatus}/>
			</Datagrid>
		</List>
	);
}
