import axios from "axios"

type InputQueryParams = { [key: string]: string | number | boolean | null }

type InputPostParams = {
	[key: string]: string | string[] | number | number[] | boolean | boolean[] | null | InputPostParams[] | InputPostParams
}

type QueryParams = Record<string, string>;

type UploadFiles = { [key: string]: File };

// エンドポイント
const endpoint = ""; // process.env.REACT_APP_API_ENDPOINT;

/**
 * 入力の変換
 * @param params
 */
const convertQueryParams = (params: InputQueryParams): QueryParams => {
	const out: QueryParams = {};
	for (let k in params) {
		let val = params[k];
		if (typeof val === "string") {
			out[k] = val;
		} else if (typeof val === "number") {
			out[k] = val.toString();
		} else if (typeof val === "boolean") {
			out[k] = val ? "1" : "0";
		} else {
			out[k] = "";
		}
	}
	return out;
}

export const ServerApi = {

	/**
	 * GET
	 * @param url
	 * @param params
	 */
	get: async function (url: string, params: InputQueryParams = {}) {
		const query_string: URLSearchParams = new URLSearchParams(convertQueryParams(params));
		const request_url = endpoint + url + "?" + query_string.toString();
		const response = await axios.get(request_url, {withCredentials: true});
		return response.data;
	},

	getAs: async function <T>(url: string, params: InputQueryParams = {}) {
		const query_string: URLSearchParams = new URLSearchParams(convertQueryParams(params));
		const request_url = endpoint + url + "?" + query_string.toString();
		const response = await axios.get<T>(request_url, {withCredentials: true});
		return response.data;
	},

	/**
	 * POST
	 * @param url
	 * @param input
	 */
	post: async function (url: string, input: InputQueryParams) {
		const data = convertQueryParams(input);
		const request_url = endpoint + url;
		const response = await axios.post(request_url, data, {withCredentials: true});
		return response.data;
	},

	/**
	 * POST and receive as
	 * @param url
	 * @param input
	 */
	postAs: async function <T>(url: string, input: InputQueryParams) {
		const data = convertQueryParams(input);
		const request_url = endpoint + url;
		const response = await axios.post<T>(request_url, data, {withCredentials: true});
		return response.data;
	},

	/**
	 * POST by JSON
	 * @param url
	 * @param data
	 */
	postJson: async function (url: string, data: InputPostParams) {
		const request_url = endpoint + url;
		const response = await axios.post(request_url, data, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	},

	/**
	 * POST by JSON and receive as
	 * @param url
	 * @param data
	 */
	postJsonAs: async function <T>(url: string, data: InputPostParams) {
		const request_url = endpoint + url;
		const response = await axios.post<T>(request_url, data, {
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.data;
	},

	/**
	 * ファイルアップロード
	 * @param url
	 * @param input
	 * @param files
	 */
	uploadFile: async function (url: string, input: InputQueryParams = {}, files: UploadFiles) {
		const post_data: FormData = new FormData();
		const params: QueryParams = convertQueryParams(input);
		Object.keys(params).forEach(function (key: string) {
			post_data.set(key, params[key]);
		});

		Object.keys(files).forEach(function (key: string) {
			post_data.append(key, files[key]);
		});

		const request_url = endpoint + url;
		const response = await axios.post(request_url, post_data, {
			headers: {
				"Content-Type": 'multipart/form-data',
			},
			withCredentials: true
		});

		return response.data;
	},
}
