const UrlPath = (base: string, params: string[]) => {
	return {
		pattern: () => {
			return base + "/" + params.map((item) => {
				return ":" + item;
			}).join("/") + "/*";
		},
		path: (...input: (string | undefined)[]) => {
			return base + "/" + input.map((item) => {
				return item ?? "";
			}).join("/");
		}
	}
}

export const AppUrls = {
	home: "/",
	settings: "/setting",
	driver_select: "/driver/select",
	vehicle_select: "/vehicle/select",
	drive_route_list: "/drive/route/list",
	drive_free_drive: "/drive/free/drive",

	drive_report_show: UrlPath("/drive/report/show", ["vehicle", "route"]),
	drive_report_started: UrlPath("/drive/report/started", ["vehicle", "route"]),
	drive_report_finished: UrlPath("/drive/report/finished", ["vehicle", "route"]),
	drive_report_error: UrlPath("/drive/report/report_error", ["vehicle", "route"]),
	drive_report_history: "/drive/report/history",
	drive_calibration_log: "/drive/calibration/start",
}
