export type ReportSentLog = {
	type: "report",
	id: string,
	timestamp: string,
	is_start: boolean,
	vehicle_id: string,
	vehicle_name: string,
	driver_id: string,
	driver_name: string,
	route_number: number,
};

export type FreeDriveSentLog = {
	type: "free_drive",
	id: string,
	timestamp: string,
	is_start: boolean,
	vehicle_id: string,
	vehicle_name: string,
	driver_id: string,
	driver_name: string,
	location: string,
};

export type CalibrationLog = {
	type: "calibration",
	id: string,
	timestamp: string,
	is_start: boolean,
	vehicle_id: string,
	vehicle_name: string,
	driver_id: string,
	driver_name: string,
	target_device: string,
};

export type HistoryRow = ReportSentLog | FreeDriveSentLog | CalibrationLog;

export const OperationHistory = {
	/**
	 * ヒストリを追加
	 * @param row
	 */
	appendRow: (row: HistoryRow) => {
		const history = OperationHistory.getRows();
		history.push(row);
		if (history.length > 100) {
			history.shift();
		}
		localStorage.setItem("report_history", JSON.stringify(history));
	},

	/**
	 * ヒストリを取得
	 */
	getRows: (): HistoryRow[] => {
		const history_str = localStorage.getItem("report_history");
		return history_str === null ? [] : JSON.parse(history_str);
	},

	/**
	 * クリア
	 */
	clear: (): void => {
		localStorage.removeItem("report_history");
	}
}
