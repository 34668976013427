import {CreateResult, Loading, RecordContextProvider, useDataProvider, useGetOne} from 'react-admin';
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Grid, SxProps, Typography} from "@mui/material";
import React, {useState} from "react";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {AppUrls} from "../../../components/common/AppUrls";
import {SessionStatus} from "../../../components/provider/SessionStatus";
import {ServerApi} from "../../../components/provider/ServerApi";
import {OperationHistory} from "../../../components/common/OperationHistory";

const sxButton: SxProps = {
	minWidth: 70,
	height: 70,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}

const sxCaption1: SxProps = {
	fontSize: "1.0em",
	height: "30px",
}

const sxCaption2: SxProps = {
	fontSize: "0.7em",
	height: "30px",
	textAlign: "center",
}

const CardCell = (props: { caption: string, content: string }) => {
	return (
		<Box sx={{padding: 1.5, border: "1px solid #ccc", borderRadius: 2}}>
			<Typography variant="caption" gutterBottom sx={{color: "#666"}}>
				{props.caption}
			</Typography>
			<Typography variant="body1" sx={{fontSize: "1.4rem"}}>
				{props.content}
			</Typography>
		</Box>
	);
}

export const DriveReportShow = () => {
	const {vehicle, route} = useParams();
	const {data, isPending} = useGetOne('report', {id: {vehicle, route}}, {retry: 1})
	const dataProvider = useDataProvider();
	const navigate = useNavigate();
	const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);

	// セッション確認
	const current_driver = SessionStatus.getDriver();

	if (isPending) {
		return <Loading/>;
	}

	// クリックイベント: 走行開始
	const handleReportStarted = () => {
		setIsButtonsDisabled(true);
		dataProvider.create("report", {
			data: {
				vehicle: vehicle,
				route: route,
				driver: current_driver.id,
				is_start: true,
			}
		}).then((response: CreateResult) => {
			const res = response.data;
			OperationHistory.appendRow({
				type: "report",
				id: res.id,
				timestamp: res.data.timestamp,
				is_start: res.data.is_start,
				vehicle_id: res.data.vehicle_id,
				vehicle_name: res.data.vehicle_name,
				driver_id: res.data.driver_id,
				driver_name: res.data.driver_name,
				route_number: res.data.route_number,
			});

			setIsButtonsDisabled(false);
			window.open(data.route_url, '_top');
		}).catch(() => {
			console.log("Failed.");
			setIsButtonsDisabled(false);
		});
	}

	// クリックイベント: 走行終了
	const handleReportFinished = () => {
		setIsButtonsDisabled(true);
		dataProvider.create("report", {
			data: {
				vehicle: vehicle,
				route: route,
				driver: current_driver.id,
				is_start: false,
			}
		}).then((response: CreateResult) => {
			const res = response.data;
			OperationHistory.appendRow({
				type: "report",
				id: res.id,
				timestamp: res.data.timestamp,
				is_start: res.data.is_start,
				vehicle_id: res.data.vehicle_id,
				vehicle_name: res.data.vehicle_name,
				driver_id: res.data.driver_id,
				driver_name: res.data.driver_name,
				route_number: res.data.route_number,
			});
			navigate(AppUrls.drive_report_finished.path(vehicle, route));
		}).catch(() => {
			console.log("Failed.");
			setIsButtonsDisabled(false);
		});
	}

	// ボタンクリック: エラー報告
	const handleReportError = () => {
		navigate(AppUrls.drive_report_error.path(vehicle, route));
	}

	// ボタンクリック: ルート確認
	const handleViewRoute = () => {
		window.open(data.route_url, '_top');
	}

	// ボタンクリック: 次の走行ルートへ
	const handleNextRoute = () => {
		setIsButtonsDisabled(true);
		ServerApi.get("/api/data/route/not_finished", {
			vehicle: vehicle ?? "",
		}).then((nextResult) => {
			console.log(nextResult);
			if (nextResult.found) {
				navigate(AppUrls.drive_report_show.path(nextResult.vehicle_id, nextResult.route_number), {replace: true});
				window.location.reload();
			} else {
				setIsButtonsDisabled(true);
				window.confirm("未走行ルートはもうありません");
			}
		}).catch(() => {
			setIsButtonsDisabled(true);
			console.log("Failed");
		})
	}

	return (
		<RecordContextProvider value={data}>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				sx={{width: '100%', margin: '0 auto', padding: 1}}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Button variant="contained" sx={sxButton} onClick={handleReportStarted}
							disabled={isButtonsDisabled || !current_driver.selected}>
						<PlayCircleIcon fontSize="large"/>
					</Button>
					<Typography variant="body2" mt={1} sx={sxCaption1}>
						開始報告
					</Typography>
				</Box>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Button variant="contained" sx={sxButton} onClick={handleReportFinished}
							disabled={isButtonsDisabled || !current_driver.selected}>
						<StopCircleIcon fontSize="large"/>
					</Button>
					<Typography variant="body2" mt={1} sx={sxCaption1}>
						終了報告
					</Typography>
				</Box>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Button variant="contained" sx={sxButton} disabled={isButtonsDisabled || !current_driver.selected}
							onClick={handleReportError}>
						<EmailIcon fontSize="large"/>
					</Button>
					<Typography variant="body2" mt={1} sx={sxCaption2}>
						ルートエラー<br/>報告
					</Typography>
				</Box>

				<Box display="flex" flexDirection="column" alignItems="center">
					<Button variant="contained" sx={sxButton} onClick={handleViewRoute} disabled={isButtonsDisabled}>
						<LocationOnIcon fontSize="large"/>
					</Button>
					<Typography variant="body2" mt={1} sx={sxCaption1}>
						ルート確認
					</Typography>
				</Box>
			</Box>

			<Box sx={{padding: 2}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CardCell caption="ドライバー" content={current_driver.display_name}/>
					</Grid>

					<Grid item xs={4}>
						<CardCell caption="車両番号" content={data.vehicle_id}/>
					</Grid>
					<Grid item xs={4}>
						<CardCell caption="ルート番号" content={data.route_number}/>
					</Grid>

					<Grid item xs={4}>
						<CardCell caption="ステータス" content={data.status ? "走行済" : "未"}/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{padding: 2}}>
				{
					data.status ?
						<Button
							variant="contained"
							fullWidth
							style={{fontSize: "1.5rem", textAlign: 'center', height: "100px"}}
							onClick={handleNextRoute}
							disabled={!data.status}
						>
							次の未走行ルートへ
						</Button> : <></>
				}
			</Box>
		</RecordContextProvider>
	);
}
