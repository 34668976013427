import type {AuthProvider} from 'react-admin';
import {ServerApi} from "./ServerApi";
import {SessionStatus} from "./SessionStatus";
import {OperationHistory} from "../common/OperationHistory";

export const CustomAuthProvider: AuthProvider = {
	async login({username, password}: { username: string, password: string }) {
		if (username === '' || password === '') {
			throw new Error('Login failed');
		}
		const data = await ServerApi.post("/api/auth/login", {
			login_id: username,
			password: password,
		}).then((data) => {
			return data;
		}).catch(() => {
			throw new Error('Logout: failed');
		});

		if (data.logged_in) {
			SessionStatus.start(data.user_id, data.user_name, data.organization);
		} else {
			throw new Error('Not Authorized');
		}
	},
	async checkError(error: any) {
		const status = error.status;
		if (status === 401 || status === 403) {
			SessionStatus.clear();
			throw new Error('Session expired');
		}
	},
	async checkAuth() {
		const data = await ServerApi.get("/api/auth/status").then((data) => {
			return data;
		}).catch(() => {
			throw new Error('Check session status: failed');
		});
		if (data.logged_in) {
			SessionStatus.resume(data.user_id, data.user_name, data.organization);
		} else {
			throw new Error('Not Authorized');
		}
	},
	async logout() {
		await ServerApi.post("/api/auth/logout", {}).then((data) => {
			OperationHistory.clear();
			return data;
		}).catch(() => {
			throw new Error('Logout: failed');
		});
		SessionStatus.clear();
	},
};
