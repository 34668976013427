import React from 'react';
import {Box, Card, CardContent, CardHeader, Grid, Typography} from '@mui/material';
import {
	CalibrationLog,
	FreeDriveSentLog,
	HistoryRow,
	OperationHistory,
	ReportSentLog
} from "../components/common/OperationHistory";
import {PrettyDatetime} from "../components/common/PrettyDatetime";

interface ItemProps {
	row: HistoryRow,
	now: number,
}

interface ItemPropsSent {
	row: ReportSentLog,
	now: number,
}

interface ItemPropsFreeDrive {
	row: FreeDriveSentLog,
	now: number,
}

interface ItemPropsCalibrationLog {
	row: CalibrationLog,
	now: number,
}

const PrettyDatetimeText = (timestamp_str: string, current_time: number): string => {
	const history_time = Date.parse(timestamp_str);
	const diff: number = Math.round((current_time - history_time) / 1000);
	return PrettyDatetime.diff_to_pretty(diff);
}

const JapaneseDatetimeText = (timestamp_str: string): string => {
	return PrettyDatetime.to_japanese_datetime(Date.parse(timestamp_str));
}

const ReportHistoryItem: React.FC<ItemProps> = ({row, now}) => {
	switch (row.type) {
		case "report":
			return <ReportSentLogItem row={row} now={now}/>
		case "free_drive":
			return <FreeDriveItem row={row} now={now}/>
		case "calibration":
			return <CalibrationLogItem row={row} now={now}/>
		default:
			return <></>;
	}
}

const DriveStatText = (props: { is_start: boolean }) => {
	return <Typography variant="body1" sx={{color: props.is_start ? "#46f" : "#f64"}}>
		{props.is_start ? "運転開始" : "運転終了"}
	</Typography>
}

const LogItemCard = (props: {
	children: any,
	title: string,
	timestamp: string,
	now: number,
	extra_info: string
}) => {
	return <Card variant="outlined" sx={{marginBottom: 2, padding: 0, width: "100%",}}>
		<CardHeader title={props.title}
					titleTypographyProps={{margin: 0, fontSize: "1.0em"}}
					sx={{padding: 0.8, backgroundColor: "#ddd"}}/>
		<Box sx={{padding: 1}}>
			<Grid container>
				<Typography variant="caption" color="text.secondary">
					{JapaneseDatetimeText(props.timestamp)} ({PrettyDatetimeText(props.timestamp, props.now)}) {props.extra_info}
				</Typography>
			</Grid>
			<Grid container>
				{props.children}
			</Grid>
		</Box>
	</Card>
}

const ReportSentLogItem: React.FC<ItemPropsSent> = ({row, now}) => {
	return <LogItemCard title="ルート走行" timestamp={row.timestamp} extra_info={row.driver_name} now={now}>
		<Grid item xs={3}>
			<Typography variant="caption" color="text.secondary">
				操作
			</Typography>
			<DriveStatText is_start={row.is_start}/>
		</Grid>
		<Grid item xs={2}>
			<Typography variant="caption" color="text.secondary">
				車両
			</Typography>
			<Typography variant="body1">
				{row.vehicle_name}
			</Typography>
		</Grid>
		<Grid item xs={7}>
			<Typography variant="caption" color="text.secondary">
				ルート
			</Typography>
			<Typography variant="body1">
				{row.route_number}
			</Typography>
		</Grid>
	</LogItemCard>
}

const FreeDriveItem: React.FC<ItemPropsFreeDrive> = ({row, now}) => {
	return <LogItemCard title="ルートなし走行" timestamp={row.timestamp} extra_info={row.driver_name} now={now}>
		<Grid item xs={3}>
			<Typography variant="caption" color="text.secondary">
				操作
			</Typography>
			<DriveStatText is_start={row.is_start}/>
		</Grid>
		<Grid item xs={2}>
			<Typography variant="caption" color="text.secondary">
				車両
			</Typography>
			<Typography variant="body1">
				{row.vehicle_name}
			</Typography>
		</Grid>
		<Grid item xs={7}>
			<Typography variant="caption" color="text.secondary">
				ルート
			</Typography>
			<Typography variant="body1">
				なし
			</Typography>
		</Grid>
	</LogItemCard>
}

const CalibrationLogItem: React.FC<ItemPropsCalibrationLog> = ({row, now}) => {
	return <LogItemCard title="キャリブレーション走行" timestamp={row.timestamp} extra_info={row.driver_name} now={now}>
		<Grid item xs={3}>
			<Typography variant="caption" color="text.secondary">
				操作
			</Typography>
			<DriveStatText is_start={row.is_start}/>
		</Grid>
		<Grid item xs={2}>
			<Typography variant="caption" color="text.secondary">
				車両
			</Typography>
			<Typography variant="body1">
				{row.vehicle_name}
			</Typography>
		</Grid>
		<Grid item xs={5}>
			<Typography variant="caption" color="text.secondary">
				デバイス
			</Typography>
			<Typography variant="body1">
				{row.target_device}
			</Typography>
		</Grid>
	</LogItemCard>
}

const ReportHistoryPage = () => {
	const history = OperationHistory.getRows().reverse();
	const now = Date.now();

	return (
		<Grid container>
			<Grid container sx={{mt: 3, mb: 3}} justifyContent="center" alignItems="center">
				<Typography variant="h5" component="div">報告送信履歴</Typography>
			</Grid>
			<Grid container>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					sx={{width: "100%"}}
				>
					{history.map((item: HistoryRow) => {
						return <ReportHistoryItem key={item.type + "/" + item.id} row={item} now={now}/>
					})}
				</Box>
			</Grid>
		</Grid>
	);
};

export default ReportHistoryPage;
