import React from 'react';
import {Button, Grid} from '@mui/material';
import {ServerApi} from "../components/provider/ServerApi";
import {useNavigate} from "react-router-dom";
import {SessionStatus} from "../components/provider/SessionStatus";

const LargeButton = (props: { onClick: () => void, label: string }) => {
	const lines = props.label.split(" ");
	const size = lines.length === 1 ? "1.2rem" : "0.95rem";
	return (
		<Button
			variant="contained"
			fullWidth
			style={{aspectRatio: '1 / 1', fontSize: size, textAlign: 'center'}}
			onClick={props.onClick}
		>
			{lines.length === 1 ? lines[0] : (<>{lines[0]}<br/>{lines[1]}</>)}
		</Button>
	);
}

const SettingPage = () => {
	const nav = useNavigate();

	const handleReload = () => {
		ServerApi.post("/api/system/refresh", {}).then(() => {
			console.log("Refresh");
		}).catch(() => {
			console.log("Failed");
		})
	}

	const handleResetDriver = () => {
		SessionStatus.resetDriver();
	}

	const handleResetVehicle = () => {
		SessionStatus.resetVehicle();
	}

	const handleBack = () => {
		nav("/");
	}

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			<Grid item xs={4}>
				<LargeButton label="データ リロード" onClick={handleReload}/>
			</Grid>
			<Grid item xs={4}>
				<LargeButton label="ドライバー リセット" onClick={handleResetDriver}/>
			</Grid>
			<Grid item xs={4}>
				<LargeButton label="車両 リセット" onClick={handleResetVehicle}/>
			</Grid>
			<Grid item xs={4}>
			</Grid>
			<Grid item xs={4}>
			</Grid>
			<Grid item xs={4}>
				<LargeButton label="戻る" onClick={handleBack}/>
			</Grid>
		</Grid>
	);
};

export default SettingPage;
