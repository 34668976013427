import * as React from "react";
import {Admin, CustomRoutes, Resource, Show} from "react-admin";
import {BrowserRouter, Route} from "react-router-dom";
import {CustomAuthProvider} from "./components/provider/CustomAuthProvider";
import CustomLoginPage from "./components/page/CustomLoginPage";
import TopPage from "./page/TopPage";
import {CustomLayout} from "./components/page/CustomLayout";
import {VehicleList} from "./page/drive/vehicle/VehicleList";
import CustomDataProvider from "./components/provider/CustomDataProvider";
import {AppUrls} from "./components/common/AppUrls";
import {RouteList} from "./page/drive/route/RouteList";
import {DriveReportShow} from "./page/drive/report/DriveReportShow";
import DriveStarted from "./page/drive/report/DriveStarted";
import DriveFinished from "./page/drive/report/DriveFinished";
import {DriverList} from "./page/drive/driver/DriverList";
import {DriveReportError} from "./page/drive/report/DriveErrorReportNew";
import SettingPage from "./page/SettingPage";
import {DriveCalibrationLogPage} from "./page/drive/report/DriveCalibrationLogNew";
import ReportHistoryPage from "./page/ReportHistoryPage";
import {DriveFreeDrivePage} from "./page/drive/report/DriveFreeDrivePage";

const App = () => (
	<BrowserRouter
		future={{
			v7_startTransition: true,
			v7_relativeSplatPath: true,
		}}
	>
		<Admin
			disableTelemetry
			title="運転報告管理"
			layout={CustomLayout}
			authProvider={CustomAuthProvider}
			dataProvider={CustomDataProvider}
			loginPage={CustomLoginPage}
		>
			<CustomRoutes>
				<Route path="/" element={
					<TopPage/>
				}/>
				<Route path={AppUrls.vehicle_select + "/*"} element={
					<Resource name="vehicle" list={VehicleList}/>
				}/>
				<Route path={AppUrls.driver_select + "/*"} element={
					<Resource name="driver" list={DriverList}/>
				}/>
				<Route path={AppUrls.drive_route_list + "/*"} element={
					<Resource name="route" list={RouteList}/>
				}/>
				<Route path={AppUrls.drive_report_show.pattern()} element={
					<DriveReportShow/>
				}/>
				<Route path={AppUrls.drive_report_started.pattern()} element={
					<DriveStarted/>
				}/>
				<Route path={AppUrls.drive_report_finished.pattern()} element={
					<DriveFinished/>
				}/>
				<Route path={AppUrls.drive_report_error.pattern()} element={
					<DriveReportError/>
				}/>
				<Route path={AppUrls.drive_calibration_log + "/*"} element={
					<DriveCalibrationLogPage/>
				}/>
				<Route path={AppUrls.drive_free_drive + "/*"} element={
					<DriveFreeDrivePage/>
				}/>
				<Route path={AppUrls.drive_report_history + "/*"} element={
					<ReportHistoryPage/>
				}/>
				<Route path={AppUrls.settings} element={
					<SettingPage/>
				}/>
			</CustomRoutes>
		</Admin>
	</BrowserRouter>
);

export default App;
