import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import {Link, useParams} from "react-router-dom";
import {AppUrls} from "../../../components/common/AppUrls";

const DriveStarted = () => {
	const {vehicle, route} = useParams();

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			sx={{width: '100%', margin: '0 auto', padding: 1}}
		>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Typography
					variant="h4"
					align="center"
					color="primary"
					sx={{
						marginTop: "3rem",
						marginBottom: "3rem",
					}}
				>
					走行を開始しました
				</Typography>
				<Grid item xs={6}>
					<Button
						variant="contained"
						fullWidth
						component={Link}
						to={AppUrls.drive_report_show.path(vehicle, route)}
						style={{fontSize: '1.9rem'}}
					>
						戻る
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DriveStarted;
