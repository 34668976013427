import {
	Create,
	Loading,
	RecordContextProvider,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	useDataProvider,
	useGetOne
} from 'react-admin';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Box, Button, Card, Grid, Typography} from "@mui/material";
import React, {useState} from "react";
import {SessionStatus} from "../../../components/provider/SessionStatus";
import {AppUrls} from "../../../components/common/AppUrls";

const CardCell = (props: { caption: string, content: string }) => {
	return (
		<Box sx={{padding: 1.5, border: "1px solid #ccc", borderRadius: 2}}>
			<Typography variant="caption" gutterBottom sx={{color: "#666"}}>
				{props.caption}
			</Typography>
			<Typography variant="body1" sx={{fontSize: "1.4rem"}}>
				{props.content}
			</Typography>
		</Box>
	);
}

const ErrorReasons = [
	{label: "工事やイベント等、一時的な通行止め/進入禁止",},
	{label: "企業敷地内など恒久的な通行止め/進入禁止",},
	{label: "道間違い（例）曲がるべき場所を通り過ぎてしまった。",},
	{label: "カメラの停止を求められた（例）大使館やテレビ局前など",},
	{label: "ナビのエラー（例）経由地を通過したが通過したことになっていない。",},
	{label: "その他（詳細を⑥に記載してください）",},
];

export const DriveReportError = () => {
	const {vehicle, route} = useParams();
	const queryParams = new URLSearchParams(useLocation().search);
	const {data, isPending} = useGetOne('report', {id: {vehicle, route}}, {retry: 1})
	const navigate = useNavigate();
	const dataProvider = useDataProvider();
	const [selectedValue, setSelectedValue] = useState('');
	const [disableButtons, setDisableButtons] = useState<boolean>(false)

	// 「戻る」ボタンの遷移先を決定する
	const return_url = queryParams.get("return") === "finished" ?
		AppUrls.drive_report_finished.path(vehicle, route) :
		AppUrls.drive_report_show.path(vehicle, route);

	// セッション確認
	const current_driver = SessionStatus.getDriver();

	const handleSelectChange = (event: any) => {
		setSelectedValue(event.target.value);
	}

	const handleClickSend = (formData: any) => {
		if (!window.confirm("ルートエラー報告を送信します。よろしいですか？")) {
			return false;
		}

		setDisableButtons(true);
		dataProvider.create("error_report", {
			data: {
				vehicle: vehicle,
				route: route,
				driver_id: current_driver.id,
				error_report: formData.reason,
				location: formData.location,
				notes: formData.notes,
			}
		}).then((response) => {
			navigate(return_url);
		}).catch((error) => {
			console.log("Failed: ", error);
			setDisableButtons(false);
		});
		return true;
	}

	if (isPending) {
		return <Loading/>;
	}

	return (
		<RecordContextProvider value={data}>
			<Typography sx={{
				textAlign: "center",
				mt: 2,
				mb: 1,
				fontSize: "2.0rem",
			}}>
				ルートエラー報告
			</Typography>
			<Box sx={{padding: 2}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CardCell caption="ドライバー" content={current_driver.display_name}/>
					</Grid>

					<Grid item xs={4}>
						<CardCell caption="車両番号" content={data.vehicle_id}/>
					</Grid>
					<Grid item xs={4}>
						<CardCell caption="ルート番号" content={data.route_number}/>
					</Grid>
					<Grid item xs={4}>
						<CardCell caption="ステータス" content={data.status ? "走行済" : "未"}/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{padding: 2}}>
				<Create resource="error_report">
					<Typography sx={{textAlign: "center", mt: 1, fontSize: "1.5rem",}}>
						報告内容
					</Typography>
					<SimpleForm id="post_create_form" toolbar={false} onSubmit={handleClickSend}>
						<SelectInput
							source="reason"
							name="reason"
							label="エラー内容"
							choices={ErrorReasons}
							optionText="label"
							optionValue="label"
							sx={{maxWidth: '300px', mb: -2}}
							onChange={handleSelectChange}
							disabled={disableButtons}
						/>
						<Card
							sx={{padding: 1, fontSize: "0.8rem", width: "100%", mb: 2}}
						>
							{selectedValue}
						</Card>
						<TextInput source="location" name="location" label="削除した目的地" multiline
								   disabled={disableButtons}/>
						<TextInput source="notes" name="notes" label="その他詳細説明等" multiline
								   disabled={disableButtons}/>
						<SaveButton form="post_create_form" label="レポートを報告" sx={{marginX: "auto"}}
									disabled={disableButtons}/>
					</SimpleForm>
				</Create>
			</Box>
			<Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mt: 1, mb: 5}}>
				<Grid item xs={6}>
					<Button
						variant="contained"
						fullWidth
						component={Link}
						to={return_url}
						style={{fontSize: '1.0rem'}}
						disabled={disableButtons}
					>
						戻る
					</Button>
				</Grid>
			</Grid>
		</RecordContextProvider>
	);
}
